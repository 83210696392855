.iiz{
    max-width:100%;
    margin:0;
    position:relative;
    overflow:hidden;
    display:inline-block;
}

.iiz--drag .iiz__zoom-img--visible{
    cursor:-webkit-grab!important;
    cursor:grab!important
}

.iiz__img{
    max-width:100%;
    height:auto;
    display:block;
    pointer-events:none;
    visibility:visible;
    opacity:1
}

.iiz__img--hidden{
    visibility:hidden;
    opacity:0
}

.iiz__img--abs{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    display:block
}

.iiz__zoom-img{
    width:auto!important;
    max-width:none!important;
    position:absolute;
    visibility:hidden;
    opacity:0;
    pointer-events:none;
    display:block
}

.iiz__zoom-img--visible{
    visibility:visible;
    opacity:1;
    pointer-events:auto;
    cursor:grab!important;
    -ms-touch-action:none;
    touch-action:none;

}

.iiz__zoom-portal{
    position:fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index:10000
}

.iiz__btn{
    background:#00000070;
    border-radius: 0.25pc;
    width:40px;
    height:40px;
    border:none;
    outline:0;
    padding:0;
    position:absolute;
    text-decoration:none;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -moz-appearance:none;
    -webkit-appearance:none;
    appearance:none;
}

.iiz__btn:before{
    content:" ";
    background-position:center;
    background-repeat:no-repeat;
    display:block
}

.iiz__hint{
    top:0.5pc;
    right:1pc;
    pointer-events:none;
    cursor:-webkit-zoom-out!important;
    cursor: zoom-out !important;
}

.iiz__hint:before{
    content:" ";
    background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.9 19.9'%3E%3Cpath d='M13.9 7.4C13.9 3.8 11 .9 7.4.9S.9 3.8.9 7.4s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5zm5.3 12.5l-6.7-7.2c-1.4 1.3-3.2 2.1-5.1 2.1-4.1 0-7.4-3.3-7.4-7.4S3.3 0 7.4 0s7.4 3.3 7.4 7.4c0 1.7-.6 3.4-1.7 4.7l6.8 7.2-.7.6z' fill='%23FFFFFF'/%3E%3C/svg%3E");
    width:20px;
    height:20px
}

.iiz__close{
    top:0.5pc;
    right:1pc;
    visibility:hidden;
    opacity:0;
    
}

.iiz__close--visible{
    visibility:visible;
    opacity:1;
    cursor:-webkit-pointer!important;
    cursor: pointer !important;
}

.iiz__close::before{
    content:" ";
    width:29px;
    height:29px;
    background-image:-webkit-gradient(linear,left top,left bottom,from(#FFFFFF),to(#FFFFFF)),-webkit-gradient(linear,left top,left bottom,from(#FFFFFF),to(#FFFFFF));
    background-image:linear-gradient(#FFFFFF,#FFFFFF),linear-gradient(#FFFFFF,#FFFFFF);
    background-size:100% 1px,1px 100%;
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg)
}