
/*Fun begins*/
.tab_container {
	width: 100%;
	margin: 0;
	position: relative;
  /*background: #e5e5e5d7;*/
}

.carousel .control-arrow,.carousel.carousel-slider .control-arrow{
  color:#000000!important; 
}

.carousel .control-arrow:focus,.carousel .control-arrow:hover{
  opacity:1;filter:alpha(opacity=100)
}

.carousel .thumb{
  padding:2px;
  margin-right: 0px!important; 
  border-color: transparent!important;
  opacity:60%!important;
}

.carousel .thumb:focus,.carousel .thumb:hover{
  /*border:2px solid!important;*/
  /*border-color:#727272!important;*/
  outline:none;
  opacity:100%!important;
}

.carousel .thumb.selected{
  border:2px solid!important;
  border-color: #333333!important;
  opacity:100%!important;
}

input, section {
  clear: both;
  display: none;
}

label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 18px;
  font-weight: bold;
  display: block;
  float: left;
  width: 50%;
  padding: 1.5em;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  background: #a1a1a1d7;
}


[id^="tab1"]:checked ~ [id^="content1"],
[id^="tab2"]:checked ~ [id^="content2"] {
  display: block;
  background: rgba(255, 255, 255, 0);

}

.tab_container [id^="tab"]:checked + label {
  background: #e5e5e5d7;
  box-shadow: inset 0px -5px 0px 0px #0137ca ;
  color: #000000;
}


/*Media query*/
@media only screen and (max-width: 900px) {
  label span {
    display: none;
  }
  
  .tab_container {
    width: 100%;
  }
}

/*Content Animation*/
@keyframes fadeInScale {
  0% {
  	transform: scale(0.9);
  	opacity: 0;
  }
  
  100% {
  	transform: scale(1);
  	opacity: 1;
  }
}

.no_wrap {
  text-align:center;
  color: #000000;
}
.link {
  text-align:center;
}