.App {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
    animation: none;
    align-self: center;
  }
}

.App-header {
  background-color:#fffffff8;
  height: 4pc;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  font-size: calc(22px + 6 * ((100vw - 320px) / 680));
  font-weight: bold;
  text-align: center;
  line-height: 90%;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #012179;
  margin-bottom: 0.5pc;
  padding-left: 0.5pc;
  padding-right: 0.5pc;
  position: sticky;
  opacity: 0.9;
  top: 0;
  box-shadow: 0px 5px 5px 2px rgb(0,0,0,0.75);
  z-index: 99;
}

.App-header-column {
  background-color: "#FFFFFF";
  width: clac(6pc + 5vmin);
  height: auto;
  display: flex;
  flex-direction: column;
  align-self: center;
  flex-wrap: nowrap;
}

.App-header-logo{
  flex-grow: 0;
  flex-shrink: 0;
  height: 4pc;
  object-fit:"scale-down";
  align-self: center;
}

.App-footer {
  /*background-color: #282c34;*/
  min-height: 5pc;
  font-size: calc(14px + 6 * ((100vw - 320px) / 680));
}

.App-footer-background {
  margin: 1pc;
  border-radius: 0.5pc;
  flex-direction: column;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 95%;
  font-size: calc(6px + 6 * ((100vw - 320px) / 680));
}

.App-footer-logo{
  flex-grow: 0;
  flex-shrink: 0;
  height: calc(5pc + 6 * ((100vw - 320px) / 680));
  object-fit:"scale-down";
}

.App.wallpaper{
  margin-left: 0.5pc;
  margin-right: 0.5pc;
};

@media (max-width: 800px) {
  .App-product-body {
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

.App-product-carousel {
  background-color: #ffffffAA;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: black;
  overflow: hidden;
  padding-bottom: 1pc;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 400px;
}

.App-product-carousel-element {
  max-height: 800px;
  aspect-ratio: 16/9;
  width: 100%;
}

.App-product-header {
  background-color: #000000 ;/*#0137ca*/
  height:fit-content;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  font-size: calc(18px + 6 * ((100vw - 320px) / 680));
  color: white;
  padding: 0.5pc;
}

.App-product {
  background-color: #ffffffAA;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  padding-bottom: 1pc;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 400px;
}

.App-product-desc {
  background-color: #ffffff00;
  height: auto;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  color: black;
  padding: 1pc;
  font-size: calc(12px + 6 * ((100vw - 320px) / 680));
}

.App-product-doc {
  background-color: #ffffff00;
  height: auto;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: black;
  font-size: calc(10px + 5 * ((100vw - 320px) / 680));
  margin: 0.5pc;
}

.App-link {
  color: #61dafb;
}

.App-pub-text{
  font-family: 'Macondo', cursive;
}

.App-order {
  width: 100%;
  height:auto;
  margin-top: 2pc;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  margin-bottom:1pc;
  margin-right: 1pc;
}

.App-order-column {
  width: 11.5pc;
  height: auto;
  display: flex;
  flex-direction: column;
  align-self: center;
  flex-wrap: nowrap;
  margin-right: 1.5pc;
  margin-bottom: 1pc;
}

.App-order-price{
  font-weight: bold;
  font-size: 1.25pc;
  text-align: center;
}

.App-order-notice{
  font-weight: normal;
  font-size: 0.75pc;
  align-self: center;
  width: 13.5pc; 
  margin-top: 0.3pc;
}

.App-order-promo{
  font-weight: bold;
  font-size: 0.75pc;
  text-align: center;
  height: 1.25pc;
}

.App-order-btn {
  width: 13.5pc; 
  background-color: #D0D0D0;
  display:flex;
  flex-direction: row;
  align-items: center;
  border: none;
  color: #0137ca ;
  text-align: center;
  text-decoration: none;
  font-size: 1.25pc;
  font-weight: bold;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  border-radius: 0.5pc;
  padding: 0.5pc;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

.App-order-btn-logo{
  width: 5pc;
  object-fit:"scale-down";
  margin-right: '1pc';

}

.App-order-btn2:hover {
  background-color: #F2F2F2;
}

.App-lang-btn {
  background-color: #D0D0D0;
  border: none;
  text-decoration: none;
  color: #0137ca ;
  font-size: calc(14px + 3 * ((100vw - 320px) / 680));
  font-weight: bold;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  border-radius: 0.5pc;
  width: 6em;
  padding-top: 0.25pc;
  padding-bottom: 0.25pc;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

.App-lang-btn2:hover {
  background-color: #0137ca;
  color: #D0D0D0;
  ;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(300deg);
  }
}

.back-to-top {
  position: fixed;
  width: 3pc;
  height: 4pc;
  bottom: 60px;
  right: 0px;
  font-size: 45px;
  background: rgba(100, 100, 100, 0.521);
  color: white;
  cursor: pointer;
  border-radius: 0.5pc;
  border: none;
  box-shadow: 0 5px 10px rgb(124, 124, 124);
}

.back-to-top:hover {
  background: rgb(104, 104, 104);
}